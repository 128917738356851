<template>
  <div>
    <div class="media">
      <div class="media-body mb-0 mt-0">
        <h5 class="font-size-14 mt-1" v-if="messageData.author.isFoundationEmployee">Od: {{ orgName }}</h5>
        <h5 class="font-size-14 mt-1" v-else>Od: {{ messageData.author.name }}</h5>
        <small class="text-muted">{{ messageData.author.email }}</small>
      </div>
    </div>
    <div class="media">
      <div class="media-body">
        <span class="font-weight-bold">Do: </span>
        <template v-if="messageData.recipients.length > 0">
          <span
              v-for="(recipient,index) in messageData.recipients.slice(0, 6)"
              :key="index">
                    {{ recipient.name }}
          </span>
        </template>
        <template v-else>
          <span>
            {{ orgName }}
          </span>
        </template>
        <br>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {},
  name: 'message-recipient-list',
  props: {
    messageData: {
      type: Object,
    },
    presentationMode: {
      type: String,
      default: 'preview',
      required: false,
    },
  },
  data() {
    return {
      recipientsModal: false,
      orgName: process.env.VUE_APP_ORG_NAME,
    }
  },
  computed: {
    isEditMode() {
      return this.presentationMode === 'edit';
    },
    isDraftMessage() {
      return this.messageData.status === 'draft';
    },
  },
  methods: {
  }
}
</script>
